exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-book-js": () => import("./../../../src/pages/book.js" /* webpackChunkName: "component---src-pages-book-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-property-js": () => import("./../../../src/pages/property.js" /* webpackChunkName: "component---src-pages-property-js" */),
  "component---src-pages-restaurant-js": () => import("./../../../src/pages/restaurant.js" /* webpackChunkName: "component---src-pages-restaurant-js" */),
  "component---src-pages-rooms-js": () => import("./../../../src/pages/rooms.js" /* webpackChunkName: "component---src-pages-rooms-js" */),
  "component---src-pages-wellness-js": () => import("./../../../src/pages/wellness.js" /* webpackChunkName: "component---src-pages-wellness-js" */)
}

